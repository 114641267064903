import React from "react";
import PropTypes from "prop-types";

const Hero = props => {
  const { backgrounds, theme } = props;

  console.log("backgrounds.mobile", backgrounds.mobile);

  return (
    <React.Fragment>
      <section className="  hero">
        <div className="login-content">
          {/* <img src="/logo.png" style={{ width: "200px", height: "181px", margin: "4em 0 2.5em" }} />
        <h1>
          DDR<div style={{ fontSize: "0.5em" }}>División de Desarrollo Regional</div>
        </h1> */}
          <div className="mascara">
            Sistema AMG Manager<div style={{ fontSize: "0.4em" }}>
              Unidad de Apoyo al Mejoramiento de la Gestión
            </div>
            <span
              style={{
                whiteSpace: "nowrap",
                position: "relative",
                fontSize: "0.3em"
              }}
            >
              División de Desarrollo Regional
            </span>
          </div>
        </div>
      </section>

      {/* --- STYLES --- */}
      <style jsx>{`
        .hero {
          background: ${theme.hero.background};
          background-image: url(${backgrounds.mobile});
          background-size: cover;
          color: ${theme.text.color.primary.inverse};
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          min-height: 100vh;
          height: 100px;
          padding: ${theme.space.inset.l};
          padding-top: ${theme.header.height.homepage};
        }

        .login-content {
          text-align: center;
          top: 345px;
          position: absolute;
        }

        .mascara {
          text-align: center;
          font-size: calc(2.8em * 1.5);
          margin: 0 0 40px 0;
          color: #ffffff;
          line-height: 1.1;
          font-family: sans-serif;
          letter-spacing: -0.04em;
          display: table;
          font-weight: 400;
        }

        button {
          background: ${theme.background.color.brand};
          border: 0;
          border-radius: 50%;
          font-size: ${theme.font.size.m};
          padding: ${theme.space.s} ${theme.space.m};
          cursor: pointer;
          width: ${theme.space.xl};
          height: ${theme.space.xl};

          &:focus {
            outline-style: none;
            background: ${theme.color.brand.primary.active};
          }

          :global(svg) {
            position: relative;
            top: 5px;
            fill: ${theme.color.neutral.white};
            stroke-width: 40;
            stroke: ${theme.color.neutral.white};
            animation-duration: ${theme.time.duration.long};
            animation-name: buttonIconMove;
            animation-iteration-count: infinite;
          }
        }

        @keyframes buttonIconMove {
          0% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-10px);
          }
          100% {
            transform: translateY(0);
          }
        }

        @media screen and (min-width: 200px) {
          .mascara {
            max-width: 100%;
            font-size: calc(2.8em);
          }
        }

        @from-width tablet {
          .hero {
            background-image: url(${backgrounds.tablet});
          }

          .mascara {
            max-width: 90%;
            font-size: calc(2.8em * 1.3);
          }

          button {
            font-size: ${theme.font.size.l};
          }
        }

        @from-width desktop {
          .hero {
            background-image: url(${backgrounds.desktop});
          }

          .mascara {
            max-width: 100%;
            font-size: calc(2.8em * 1.5);
          }

          button {
            font-size: ${theme.font.size.xl};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Hero.propTypes = {
  scrollToContent: PropTypes.func.isRequired,
  backgrounds: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default Hero;
